import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import TripleColumnBlock from "@components/tripleColumnBlock"

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const WebsiteDesignSEOServicePage = () => {
  return (
    <PageLayout>
      <SEO
        title="Software Development Company - Websuasion"
        ogDescription="Learn what you need from a software development company. From user interface down to the database, we are there through the development cycle and beyond."
        image="/images/development/software-development-companies.jpg"
      />
		  <TextBlock
		    textPadded
		    textLeft={
		      <div>
						<h1>Software Development Company</h1>
						<p>
							We are an Atlanta-based software development company with over 25 years of expertise in app development. We guide you through the development lifecycle. You get scalable strategies whether your business is B2B or B2C. We serve financial, medical, service, manufacturing, logistics, entertainment, digital learning, and retail industries. Our developers use <a href="https://www.ruby-lang.org/en/">Ruby</a>, <a href="https://www.sencha.com/products/extjs/">ExtJS</a>, <a href="https://www.javascript.com/">JavaScript</a>, <a href="https://nodejs.org/en/">Node</a>, and <a href="https://reactjs.org/">ReactJS</a> to solve a wide range of issues.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
		      </div>
		    }
		    textRight={
		      <div>
					<img src="/images/development/software-development-companies.jpg" className="rounded-image" alt="Software Development Company - Custom Programming - Websuasion" />
		      </div>
		    }
		  />
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Critical Roles In A Software Development Company</h2>
							<p>
								Understand the difference between <a href="/development/front-end-web-developer/">web designers</a>, <a href="/development/website-back-end-developer/">application programmers</a>, and software engineers. Each plays an important role in web and mobile application development. But their individual skill sets, tools, and purpose differ. It's important to work with a software development company employing experienced individuals across these roles. No one person can be good at it all.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
						<h2>Marketing Web Designer</h2>
						<p>
							Web Designers often come from a graphics and design background. Their technical skill centers around scripting languages like HTML5 and CSS. They make sure your website adheres to web standards.
						</p>
						<p>
							The Web Designer's role is generally within the marketing realm. They ensure your marketing website speaks to your customer base. But, they also dictate your position in search results. Web Designers work together with content producers toward <a href="/digital-marketing-agency-atlanta/search-engine-optimization-atlanta/">search engine optimization</a> (SEO). They configure digital advertising lead-funnels and re-marketing campaigns.
						</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
		        <div>
							<h2><a href="/development/front-end-web-developer/">Front-end Web Developer</a></h2>
							<p>
								<a href="/development/front-end-web-developer/">Front-end Web Developers</a> are often confused with Web Designers. That's understandable, as they both deal with how a website functions in a web browser. But those roles split at the point of login. When you log into a website, much of the marketing and SEO concerns no longer exist. Search engines can't see beyond your login. And once logged into your system, you've captured the lead. From there, you manage the sales cycle. Front-end Web Developers craft the customer journey so they are a satisfied customer.
							</p>
							<p>
								At that login point, user interface and user experience become the focus. Front-end developers are called User Interface/User Experience. Abbreviated <a href="https://www.mediabistro.com/climb-the-ladder/skills-expertise/what-does-a-ux-ui-designer-do/">UI/UX</a>, their skill set includes the <a href="https://en.wikipedia.org/wiki/HTML5">HTML5</a> and <a href="https://en.wikipedia.org/wiki/Cascading_Style_Sheets">CSS</a> scripting of a Web Designer. But they also have extensive knowledge of JavaScript programming. They use robust user interface frameworks like <a href="https://reactjs.org/">ReactJS</a> and <a href="https://angularjs.org/">AngularJS</a>. Working with <a href="/development/website-back-end-developer/">back-end developers</a>, they connect your user to cloud servers.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/software-development-user-experience.jpg" className="rounded-image" alt="Software Development Company - Custom Programming - Websuasion" />
			      </div>
			    }
			  />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2><a href="/development/website-back-end-developer/">Back-end Web Developer</a></h2>
							<p>
								Back-end web developers focus on the business logic and data flow in your system. They use programming languages including <a href="https://www.ruby-lang.org/en/">Ruby</a>, <a href="https://www.python.org/">Python</a>, <a href="https://www.java.com/en/">Java</a>, and <a href="https://www.javascript.com/">JavaScript</a>. They write complex algorithms and logic. Application structure and testing ensure your app is scalable, maintainable and expandable.
							</p>
							<p>
								Our developers follow the practice of <a href="https://en.wikipedia.org/wiki/Test-driven_development">test-first</a> programming - also known as behavioral-driven design. This approach allows you to be fearless when adding new app features. We always know the effects of changes down-stream.
							</p>
							<p>
								<a href="/development/website-back-end-developer/">Back-end web developers</a> work with databases creating a <a href="https://en.wikipedia.org/wiki/Database_normalization">normalized schema</a> and writing efficient queries. They provide the front-end developers the structured data rules. And they adhere to the design specifications provided by the Software Engineer.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
			  <TextBlock
			    textPadded
			    textLeft={
		        <div>
							<h2><a href="https://websuasion.com/about-our-app-developers/">Software Engineer</a></h2>
							<p>
								Think of a software engineer in the same light as an architect. You wouldn't build a house without a blueprint. You likewise shouldn't build your business system without a detailed application architecture plan. The software engineer will determine the structure of your enterprise software. The Entity Classes of your system represent the nouns of your business. The Service Classes of your system represent the verbs ... or actions that are taken. Associations between Entity Classes define the relationships of data in your system.
							</p>
							<p>
								The <a href="https://websuasion.com/about-our-app-developers/">software engineer</a> is the point of contact in a software development company who works directly with stakeholders in your company. They are the first to consult with you on your project. Through the development process, they may oversee project management. Their job is to make sure your app design solves the problems you know. But also, they design for flexibility for the future problems you don't know.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/application-architecture.jpg" className="rounded-image" alt="Software Development Company - Custom Programming - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
		        <div>
							<h2>Quality Assurance Testing</h2>
							<p>
								Quality Assurance testers use your system in parallel with developers. They assist with each iteration and catch unexpected issues. They scrutinize the user interface. Pixel by pixel. Left to right. Top to bottom. Their job is to try and break the system.
							</p>
							<p>
								Developers tend to know too much about how your system should work. Quality Assurance Testers approach the use of the system as a typical user. They are the user's advocate asking questions about functionality when usability is unclear. They provide feedback to the front-end developers on usability. And they reveal logical errors for back-end developers.
							</p>
							<p>
								QA Testers submit bug and error tickets. They play a role in day to day project management by tracking ticket status. Often, QA Testers will interact with users in the beta testing phase. Once deployed, QA testers take part in user training. Their user perspective and knowledge of the system makes them an excellent guide.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/software-development-quality-assurance-testing.jpg" className="rounded-image" alt="Software Development Company - Custom Programming - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2><a href="/development/digital-transformation-consulting-firm/">Digital Transformation</a> From Your Software Development Company</h2>
							<p>
								The first step of Digital Transformation in software development is learning. We get to know the ins-and-outs of your business from sales through to accounts receivable. Every established business develops with inefficient systems. You may rely on spreadsheets. You may have disconnected systems requiring duplicate data entry. There is always room to <a href="https://websuasion.com/digital-transformation-consulting-firm/">improve your processes, productivity, and workforce efficiency</a>. Automation will lower administration costs.
							</p>
							<p>
								For sales, custom software will take you from prospect and close with greater efficiency. Software facilitates communication between clients and staff. It makes possible digital collaboration across departments. There is no excuse for disconnected systems. Your custom business system can also integrate with third-party software. We work with services like <a href="https://www.salesforce.com/">Salesforce</a>, <a href="https://quickbooks.intuit.com/global/cloud-accounting-software/">Quickbooks Cloud</a> and <a href="https://cloud.google.com/apis/docs/overview">Google</a>. We migrate and clean your legacy data to provide greater insight into your business.
	          	</p>
						</div>
	        }
	      />
			</PageWrapper>
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block grid-image">
						<h3>Product Development</h3>
						<p>
							You have an app idea and need a firm that will guide you from concept consultation through to deployment. What are the technical requirements and challenges? Do you need a cross-platform mobile iOS and Android app? Or will a client web portal be a more cost-effective and flexible initial strategy? Should you launch your app as Software as a Service (SaaS)? Or use an enterprise implementation deployment strategy? We will guide you.
          	</p>
					</div>
        }
        textCenter={
          <div className="general-block grid-image">
						<h3>Data Analytics</h3>
						<p>
							Data collection and reporting allows you to see what is happening in your business. Through advanced analytics, you can identify and mitigate risks with greater speed. Data visualization reveals otherwise unseen opportunities for cost reduction and revenue streams. Make sure your business intelligence is in place so that you can make key decisions. Let us show you what's possible.
          	</p>
					</div>
        }
        textRight={
          <div className="general-block grid-image">
						<h3>Deployment</h3>
						<p>
							You can rely on the hardened security and performance of Ubuntu Linux servers in the cloud. We provide data backups and management of digital assets to mitigate loss. Our managed hosting and uptime monitoring keep your applications live and reduce vulnerability. Let us set your mind at ease.
          	</p>
					</div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default WebsiteDesignSEOServicePage
